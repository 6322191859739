.attachment-block {
    width: 630px;
    margin: auto;
    padding: 0px 15px;
}

.attachment-block .attachment,
.attachment-block .attachment i,
.attachment-block .attachment p {
    display: inline-block;
}


.attachment-block .attachment p {
    margin-left: 5px !important;
}

.attachment-block .attachment {
    background-color: grey;
    border-radius: 10px;
    padding: 5px 10px;
    color: #ffffff;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 10px;
    outline: none;
    border: none;
}

.attachment-block .attachment:disabled {
    color: rgba(0, 0, 0, 0.26);
    background-color: #0000001f;
}

.attachment-seperator {
    width: 600px;
}

@media only screen and (max-width: 600px) {
    .attachment-seperator {
        width: 100%;
    }
}