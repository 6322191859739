.roadmapIFrameContainer {
  position: relative;
  width: 100%;
  padding-top: 48.97%;
  border: 2px solid #e71d73;
  border-radius: 20px;
  height: 100%;
  overflow: hidden;
}

.roadmapIFrame {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border: none;
}

.journey-key-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.journey-col {
  border: 2px solid #e71d73;
  border-radius: 20px;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.journey-col-header {
  color: #e71d73;
  font-weight: bold;
}

.journey-container {
  margin-top: 45px;
  max-width: 85%;
  margin-left: auto;
  margin-right: auto;
}

.chat-button {
  margin-top: 0px;
  height: auto;
  font-size: 18px;
}

.key-icons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media screen and (max-width: 768px) {
  .roadmapIFrameContainer {
    position: relative;
    width: 100%;
    padding-top: 227%;
  }
}

.detailed-next-steps-header {
  text-decoration: underline;
  cursor: pointer;
}