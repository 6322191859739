.referral-history-block {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background-color: #f4f4f4;
    padding: 0px 50px 30px 50px;
    border: 1px solid #E0E1E2;
    border-radius: 20px;
}

.referral-history-block .filter-label {
    font-size: 15px;
    font-weight: bolder;
    color: #717171;
    margin-bottom: 10px;
}

.referral-history-block .filter-block {
    margin-top: 30px;
}

.referral-history-block .filter-block > div {
    margin-right: 15px;
    margin-bottom: 10px;
    max-width: 300px;
}

.referral-history-bonus {
    font-size: 16px;
    color: #717171;
    font-weight: bolder;
}

@media only screen and (max-width: 767px) {
    .referral-history-block {
        padding: 0 10px 10px 10px;
    }
}