.account-holder-information-heading {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 24px;
    color: #e71d73;
    text-align: center;
    padding-bottom: 20px;
}

.account-holder-information-text {
    color: #e71d73;
}

.joint-owner-form-download-button {
    display: flex;
    justify-content: center;
    padding-bottom: 15px;
}