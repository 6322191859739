.joint-owner-col-block {
    padding: 0px 10px;
    width: 33.33%;
}

.no-joint-owner-text {
    display: flex;
    justify-content: center;
    padding-top: 35px;
}

@media only screen and (max-width: 767px) {
    .joint-owner-col-block {
        width: 100%;
    }
}