.faq-block {
    max-width: 1200px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background-color: #f4f4f4;
    padding: 0px 50px 30px 50px;
    border: 1px solid #E0E1E2;
    border-radius: 20px;
}

.faq-block .faq-title {
    margin-top: 30px;
    margin-bottom: 30px;
}

.faq-block .list-group .list-group-item {
    background: transparent;
}

.faq-block .list-group .list-group-item a {
    color: #ec008c;
    text-decoration: none;
}

@media only screen and (max-width: 767px) {
    .faq-block {
        padding: 0 10px 10px 10px;
    }
}