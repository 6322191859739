.anim-active-shadow-on {
    animation: active-shadow-on 0.3s ease-in 0s 1 normal;
    -webkit-animation: active-shadow-on 0.3s ease-in 0s 1 normal;
    -moz-animation: active-shadow-on 0.3s ease-in 0s 1 normal;
}

.anim-active-shadow-off {
    animation: active-shadow-off 0.3s ease-in 0s 1 normal;
    -webkit-animation: active-shadow-off 0.3s ease-in 0s 1 normal;
    -moz-animation: active-shadow-off 0.3s ease-in 0s 1 normal;
}

.anim-opacity-in {
    animation: opacity-in 0.3s ease-in 0s 1 normal;
    -webkit-animation: opacity-in 0.3s ease-in 0s 1 normal;
    -moz-animation: opacity-in 0.3s ease-in 0s 1 normal;
}

.anim-opacity-out {
    animation: opacity-out 0.3s ease-in 0s 1 normal;
    -webkit-animation: opacity-out 0.3s ease-in 0s 1 normal;
    -moz-animation: opacity-out 0.3s ease-in 0s 1 normal;
}

.anim-opacity-in-out {
    animation: opacity-in-out 3s ease-in 0s 1 normal;
    -webkit-animation: opacity-in-out 3s ease-in 0s 1 normal;
    -moz-animation: opacity-in-out 3s ease-in 0s 1 normal;
}


/* active-shadow-on */

@keyframes active-shadow-on {
    0% {
        box-shadow: 0 0 15px #eeeeee;
    }
    100% {
        box-shadow: 0 0 15px #e71d73;
    }
}

@-webkit-keyframes active-shadow-on {
    0% {
        box-shadow: 0 0 15px #eeeeee;
    }
    100% {
        box-shadow: 0 0 15px #e71d73;
    }
}

@-moz-keyframes active-shadow-on {
    0% {
        box-shadow: 0 0 15px #eeeeee;
    }
    100% {
        box-shadow: 0 0 15px #e71d73;
    }
}


/* active-shadow-on */


/* active-shadow-off */

@keyframes active-shadow-off {
    0% {
        box-shadow: 0 0 15px #e71d73;
    }
    100% {
        box-shadow: 0 0 15px #eeeeee;
    }
}

@-webkit-keyframes active-shadow-off {
    0% {
        box-shadow: 0 0 15px #e71d73;
    }
    100% {
        box-shadow: 0 0 15px #eeeeee;
    }
}

@-moz-keyframes active-shadow-off {
    0% {
        box-shadow: 0 0 15px #e71d73;
    }
    100% {
        box-shadow: 0 0 15px #eeeeee;
    }
}


/* active-shadow-off */


/* opacity-in */

@keyframes opacity-in {
    0% {
        opacity: 0;
        z-index: -100;
    }
    40% {
        opacity: 0.5;
        z-index: -50;
    }
    100% {
        opacity: 1;
        z-index: 0;
    }
}

@-webkit-keyframes opacity-in {
    0% {
        opacity: 0;
        z-index: -100;
    }
    40% {
        opacity: 0.5;
        z-index: -50;
    }
    100% {
        opacity: 1;
        z-index: 0;
    }
}

@-moz-keyframes opacity-in {
    0% {
        opacity: 0;
        z-index: -100;
    }
    40% {
        opacity: 0.5;
        z-index: -50;
    }
    100% {
        opacity: 1;
        z-index: 0;
    }
}


/* opacity-in */


/* opacity-out */

@keyframes opacity-out {
    0% {
        opacity: 1;
        z-index: 0;
    }
    40% {
        opacity: 0.5;
        z-index: -50;
    }
    100% {
        opacity: 0;
        z-index: -100;
    }
}

@-webkit-keyframes opacity-out {
    0% {
        opacity: 1;
        z-index: 0;
    }
    40% {
        opacity: 0.5;
        z-index: -50;
    }
    100% {
        opacity: 0;
        z-index: -100;
    }
}

@-moz-keyframes opacity-out {
    0% {
        opacity: 1;
        z-index: 0;
    }
    40% {
        opacity: 0.5;
        z-index: -50;
    }
    100% {
        opacity: 0;
        z-index: -100;
    }
}


/* opacity-out */


/* opacity-in-out */

@keyframes opacity-in-out {
    0% {
        opacity: 0
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-webkit-keyframes opacity-in-out {
    0% {
        opacity: 0
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@-moz-keyframes opacity-in-out {
    0% {
        opacity: 0
    }
    20% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}


/* opacity-in-out */