.dashboard-block {
    margin-top: 45px;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
}

.dashboard-block>.dashboard-title {
    color: #e71d73;
    font-weight: bold;
    border: 2px solid #e71d73;
    border-radius: 20px;
    padding: 3px 50px 3px 50px;
    margin-top: -17px;
    background: #ffffff;
    text-align: center;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    position: relative;
    z-index: 10;
}

.dashboard-block>.dashboard-title.no-account {
    margin-top: 20px;
}

.dashboard-block>.dashboard-title>p {
    margin: 0;
}

.dashboard-block>.concession-superman{
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled-menu-overlay {
    position: absolute;
    top: 50%;
    left: 45%;
    cursor: pointer;
}

.dashboard-block>.dashboard-content {
    border: 2px solid #e71d73;
    border-radius: 20px;
    padding: 50px 35px 20px 35px;
    position: relative;
    margin-top: -17px;
}

.dashboard-content p {
    margin: 0;
}

.dashboard-content .dashboard-item {
    display: flex;
    flex-direction: row;
    /* width: 33.33%; */
    min-width: 350px;
    word-break: break-all;
    min-height: 215px;
}

.left-dashboard-item,
.right-dashboard-item {
    width: 30%;
}

.dashboard-item>div {
    width: 100%;
    margin: 0 10px 0 10px;
    position: relative;
}

.dashboard-item .dashboard-refer {
    display: block;
    width: 90%;
    max-width: 500px;
    margin: auto;
}

.dashboard-content .dashboard-item hr {
    display: none;
}

.dashboard-block .dashboard-item .dashboard-seperator {
    height: 80%;
    vertical-align: middle;
    width: 1px;
    background-color: #dedede;
}

.dashboard-item-button {
    position: absolute;
    bottom: 0;
    margin-bottom: 10px;
}

@media only screen and (max-width: 1064px) {
    .dashboard-content .dashboard-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: auto;
        min-height: auto;
    }

    .dashboard-content .dashboard-item hr {
        display: block;
        width: 100%;
        margin: 20px 0;
    }

    .left-dashboard-item,
    .right-dashboard-item {
        width: fit-content;
    }

    .dashboard-block .dashboard-item .seperator {
        display: none;
    }

    .dashboard-block .dashboard-item>div {
        margin: 0px;
    }

    .dashboard-block>.dashboard-content {
        padding: 50px 20px 20px 20px;
    }

    .dashboard-refer {
        width: 100%;
    }

    .dashboard-item-button {
        width: 100%;
        position: initial !important;
    }
}

.menu-block {
    margin-top: 45px;
    max-width: 85%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    position: relative;
    padding-bottom: 50px;
}

.menu-item {
    margin-bottom: 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200px;
    z-index: 0;
    background: rgb(255, 255, 255);
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 0 15px #eeeeee;
}

.menu-item-container {
    position: relative;
    width: 30%;
    height: 200px;
    margin-top: 20px;
}

@media only screen and (max-width: 768px) {

    /* For mobile phones: */
    .menu-item-container {
        width: 100%;
    }

    .blank-holder-tile {
        display: none;
    }

    .menu-item-active .menu-item-content>div>p {
        text-decoration: underline;
    }
}

.menu-item-active {
    height: 280px;
    z-index: 100;
    box-shadow: 0 0 15px #e71d73;
    animation: menu-active 0.3s ease-in 0s 1 normal;
    -webkit-animation: menu-active 0.3s ease-in 0s 1 normal;
    -moz-animation: menu-active 0.3s ease-in 0s 1 normal;
}

.menu-item-shrink {
    animation: menu-shrink 0.3s ease-in 0s 1 normal;
    -webkit-animation: menu-shrink 0.3s ease-in 0s 1 normal;
    -moz-animation: menu-shrink 0.3s ease-in 0s 1 normal;
}

@media only screen and (max-width: 1270px) and (min-width: 768px) {
    .menu-item-long.menu-item-active {
        height: 325px;
    }

    .menu-item-long.menu-item-active {
        animation: menu-active-long 0.3s ease-in 0s 1 normal;
        -webkit-animation: menu-active-long 0.3s ease-in 0s 1 normal;
        -moz-animation: menu-active-long 0.3s ease-in 0s 1 normal;
    }

    .menu-item-long.menu-item-shrink {
        animation: menu-shrink-long 0.3s ease-in 0s 1 normal;
        -webkit-animation: menu-shrink-long 0.3s ease-in 0s 1 normal;
        -moz-animation: menu-shrink-long 0.3s ease-in 0s 1 normal;
    }
}

.menu-item-title,
.menu-item-title-active {
    font-size: 23px;
    font-weight: bold;
    padding: 10px 20px;
    transition: background-color;
    transition-duration: 0.2s;
}

.menu-item-title {
    background-color: #4b4b4b;
}

.menu-item-title-disabled {
    background-color: #969696;
}

.menu-item-title>a,
.menu-item-title-active>a {
    text-decoration: none;
    color: #ffffff;
}

.menu-item-title-active {
    background-color: #e71d73;
}

.menu-item-icon {
    float: right;
    margin-top: -62px;
}

.menu-item-icon img {
    width: 120px;
    height: 100px;
}

.menu-item-content,
.menu-item-content-active {
    height: 146px;
    padding: 20px 20px 10px 20px;
}

.menu-item-content>div>p {
    cursor: pointer;
    line-height: 1.2;
    margin-bottom: 8px;
}

.menu-item-active .menu-item-content>div>p:hover {
    color: #e71d73;
}

.table-dashboard th,
.table-dashboard td {
    text-align: center;
}

.scroll-hint {
    position:fixed;
    bottom:0;
    width:10%;
    text-align:center;
    z-index:1000;
    display:block;
    color:#c7c7c7;
    font-size:25px;
    background-color: rgba(0, 0, 0, 0.5);
    margin-left:45%;
    margin-right:45%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    -webkit-transition: opacity 1s ease-in-out;
    -moz-transition: opacity 1s ease-in-out;
    -ms-transition: opacity 1s ease-in-out;
    -o-transition: opacity 1s ease-in-out;
    transition: opacity 1s ease-in-out;
}

@media only screen and (max-width: 1064px) {

    .scroll-hint {
        width: 20%;
        margin-left: 40%;
        margin-right: 40%;
    }
}

.hide-scroll-hint {
    -webkit-transition: opacity 2s ease-in-out;
    -moz-transition: opacity 2s ease-in-out;
    -ms-transition: opacity 2s ease-in-out;
    -o-transition: opacity 2s ease-in-out;
    transition: opacity 2s ease-in-out;
    opacity: 0;
}

/* menu-active */

@keyframes menu-active {
    0% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }

    100% {
        height: 280px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }
}

@-webkit-keyframes menu-active {
    0% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }

    100% {
        height: 280px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }
}

@-moz-keyframes menu-active {
    0% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }

    100% {
        height: 280px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }
}

@keyframes menu-active-long {
    0% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }

    100% {
        height: 325px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }
}

@-webkit-keyframes menu-active-long {
    0% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }

    100% {
        height: 325px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }
}

@-moz-keyframes menu-active-long {
    0% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }

    100% {
        height: 325px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }
}

/* menu-shrink */

@keyframes menu-shrink {
    0% {
        height: 280px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }

    100% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }
}

@-webkit-keyframes menu-shrink {
    0% {
        height: 280px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }

    100% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }
}

@-moz-keyframes menu-shrink {
    0% {
        height: 280px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }

    100% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }
}

@keyframes menu-shrink-long {
    0% {
        height: 325px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }

    100% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }
}

@-webkit-keyframes menu-shrink-long {
    0% {
        height: 325px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }

    100% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }
}

@-moz-keyframes menu-shrink-long {
    0% {
        height: 325px;
        box-shadow: 0 0 15px #e71d73;
        z-index: 100;
    }

    100% {
        height: 200px;
        box-shadow: 0 0 15px #eeeeee;
        z-index: 0;
    }
}