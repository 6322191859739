.send-referral-block {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
}

.send-referral-subheading {
    color: #2e2e2d;
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: normal;
}

.send-referral-scanqrcode-text {
    color: #696969;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: normal;
}

.copy-button-wrapper {
    width: 25%;
    display: inline-block;
}

.copy-button {
    border: 1px solid #e71d73;
    border-radius: 0 20px 20px 0;
    padding: 3px 10px;
    vertical-align: top;
    text-align: center;
    background-color: #e71d73;
    color: #ffffff;
    cursor: pointer;
    min-width: min-content;
    width: 10%;
    flex: 0 0 auto;
}

@media only screen and (max-width: 767px) {
    .referral-link-block {
        padding: 0 10px 10px 10px;
    }
}