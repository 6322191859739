.globird-selector-container {
    height: 36px;
}

.globird-selector {
    /* position: relative; */
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    overflow: hidden;
    /* z-index: 11; */
}

.globird-selector:hover{
    position: relative;
    border: 1px solid #e71d73;
    z-index: 11;
}

.globird-selector .ellipsis {
    width: 100%;
    text-align: center;
    white-space: nowrap;  
    text-overflow: ellipsis; 
    overflow: hidden;
}

.globird-selector .selection {
    display: flex;
    justify-content: space-between;
    color: #7a7979;
    text-decoration: none;
    padding: 4px 13px 4px 25px;
    font-weight: bold;
    background-color: #ffffff;
    cursor: pointer;
}

.globird-selector .collapse-option {
    display: none;
    position: relative;
    z-index: 30;
    background-color: #ffffff;
    width: 100%;
    transition: height 0.2s ease-in;
}

.globird-selector .option-list .option {
    padding: 3px 25px 3px 25px;
    cursor: pointer;
}

.globird-selector .option-list .option:hover {
    background-color: #e71d73;
    color: #ffffff;
}