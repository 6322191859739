.supperman {
    border-radius: 100px;
    padding: 15px;
    width: 100%;
    background-color: #fff;
}

.supperman-img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.supperman-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #e71d73;
    text-align: center;
    display: flex;
    align-items: center;
    width: inherit;
    justify-content: center;
}