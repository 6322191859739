/* Reconnection List */
.reconnection-panel {
    width: 100%;
    min-width: 260px;
}

.reconnection-heading {
    margin-bottom: 0%;
    font-weight: 700;
    font-size: 2.2rem;
}

.reconnection-heading-description {
    font-size: 0.90rem;
}

.reconnection-table {
    margin-top: 1rem;
}

.site-address-column {
    max-width: 280px;
}

/* Reconnection retry */
.reconnection-retry-heading-description {
    font-size: 0.90rem;
    color: #e71d73;
}

.reconnection-retry-panel {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 1rem;
}

.reconnection-retry-site-block {
    width: 50%;
    margin-right: auto;
}

.reconnection-retry-site-header {
    font-weight: bold;
    color: #696969;
    padding: 0px 25px 0px 25px;
}

.reconnection-retry-site-body {
    background-color: #e9edf1;
    color: #515151;
    border-radius: 18px;
    padding: 11px 25px 11px 25px
}

.reconnection-retry-date-block {
    width: 50%;
    padding: 0px 1rem;
}

.reconnection-retry-date-row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    margin-top: 22px;
}

.reconnection-today-button {
    margin: 0px;
    /* height: 2rem; */
    font-size: 1.5rem;
}

.reconnection-retry-date-or {
    border: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 1rem;
    margin-right: 1rem;
    font-size: 0.8rem;
    border-radius: 10px;
    color: white;
    background-color: black;
}

.reconnection-date-item {
    margin: 0px;
}

.reconnection-agree-block {
    width: 100%;
    margin-top: 1rem;
}

.reconnection-agree-col-block {
    width: 50%;
    padding: 0px 1rem;
}

.reconection-fee-table-header {
    color: #e71d73;
    font-weight: 700;
}

.reconection-fee-table-row {
    color: #e71d73;
}

.reconection-agree-terms-warning {
    font-weight: 700;
}

.reconnection-agree-text li {
    color: #e71d73;
}

.reconnection-agree-col-block ul {
    padding: 0px 1rem;
}

@media only screen and (max-width: 768px) {
    .reconnection-heading {
        line-height: 2.2rem;
        margin-bottom: 0.5rem;
    }

    .reconnection-retry-site-block {
        width: 100%;
        padding: 0px;
    }

    .reconnection-retry-date-block {
        width: 100%;
        padding: 0px;
    }

    .reconnection-agree-col-block {
        width: 100%;
        padding: 0px;
    }
}