.globird-or-pip {
    border: 20px;
    padding: 2px 15px;
    font-size: 0.8rem;
    border-radius: 99px;
    color: white;
    background-color: #2e2e2d;
    display: inline-block;
    font-weight: 600;
}

.divider span {
    padding: 0 !important;
}