.usage-chart-block {
  max-width: 1200px;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  background-color: #f4f4f4;
  padding: 0px 50px 30px 50px;
  border: 1px solid #E0E1E2;
  border-radius: 20px;
}

.usagechart-item{
  width: 100%;
  padding: 10px 0;
  font-size: 16px;
}
.usagechart-item>p{
  line-height: 40px;
  font-size: 20px;
}
.usagechart-item-select{
  min-width: 200px;
  max-width: 90%;
  margin-right: 30px;
}

.usagechart-item-select :global(.ant-select-selector) {
  height: fit-content !important;
}

:global(.ant-select-item-option-content) {
  white-space: normal;
  height: fit-content;
}

.usagechart-item-select :global(.ant-select-selection-item) {
  white-space: normal;
  height: fit-content;
}

.usagechart-chart {
  margin: 30px 0;
}

@media only screen and (max-width: 767px) {
  .usage-chart-block {
    padding: 0 10px 10px 10px;
  }
}