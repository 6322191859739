.ant-select,
.ant-picker,
.ant-picker-borderless,
.ant-picker.ant-picker-borderless,
.ant-input-affix-wrapper,
.ant-input-number,
.ant-input {
    border-radius: 20px;
}

.ant-select-selector{
    border-radius: 20px !important;
}

.ant-select-dropdown,
.ant-picker-panel-container,
.ant-btn {
    border-radius: 20px;
}

.ant-select-selector {
    padding: 0 10px !important;
}

textarea.ant-input {
    border-radius: 20px;
}

.ant-checkbox-inner {
    border-radius: 3px !important;
    border: 1px solid #8F96A1;
    background-color: #E2E2E2;
}

.ant-picker-focused,
.ant-select-focused {
    box-shadow: none;
}

.ant-col.ant-form-item-label {
    padding: 0;
}

.ant-form-item-explain.ant-form-item-explain-error {
    padding-left: 10px;
}


@media only screen and (max-width: 767px) {
    .ant-picker-panel-container .ant-picker-panels {
        flex-direction: column;
    }

    .ant-picker-datetime-panel {
        flex-direction: column;
    }
}

.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-item-link {
    border-radius: 12px !important;
}

.ant-list-split .ant-list-item {
    border-bottom: 1px solid #e4e4e4 !important;
}

.ant-input-group-addon {
    border: 0px;
    padding: 0px;
}

.ant-input-group > span:last-child > * {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.ant-input-group > span:last-child > * > * {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.ant-input-group-compact > *:first-child {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.ant-input-group-compact > *:first-child > * {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.ant-input-group-compact > *:last-child {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.ant-modal-content {
    border-radius: 20px !important;
}

.ant-modal-header {
    border-radius: 20px 20px 0 0 !important;
}

.ant-modal-footer {
    border-radius: 0 0 20px 20px !important;
}