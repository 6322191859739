.flex-y {
    flex-direction: column;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-item-solid {
    flex-grow: 0;
    flex-shrink: 0;
}

.flex-left-top,
.flex-top-left-y {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-left-center,
.flex-top-center-y {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.flex-left-bottom,
.flex-top-right-y {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.flex-center-top,
.flex-center-left-y {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.flex-center-center,
.flex-center-center-y {
    display: flex;
    justify-content: center;
    align-items: center;
}

.flex-center-bottom,
.flex-center-right-y {
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

.flex-right-top,
.flex-bottom-left-y {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.flex-right-center,
.flex-bottom-center-y {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.flex-right-bottom,
.flex-bottom-right-y {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.flex-between-top,
.flex-between-left-y {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}

.flex-between-center,
.flex-between-center-y {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.flex-between-bottom,
.flex-between-right-y {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.flex-around-top,
.flex-around-left-y {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
}

.flex-around-center,
.flex-around-center-y {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-around-bottom,
.flex-around-right-y {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.flex-evenly-top,
.flex-evenly-left-y {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
}

.flex-evenly-center,
.flex-evenly-center-y {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.flex-evenly-bottom,
.flex-evenly-right-y {
    display: flex;
    justify-content: space-evenly;
    align-items: flex-end;
}

@media only screen and (max-width: 768px) {
    [class*="flex-between-"] {
        justify-content: center;
    }
}