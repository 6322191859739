.assistance-block {
    max-width: 500px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background-color: #f4f4f4;
    padding: 0px 50px 30px 50px;
    border: 1px solid #E0E1E2;
    border-radius: 20px;
}

.assistance-form {
    /* width: fit-content; */
    margin: 30px auto;
}

.request-content-textarea {
    border-radius: 20px;
    min-height: 130px !important;
}

.assistance-phone-block > .phone-icon,
.assistance-phone-block > p {
    display: inline-block;
    vertical-align: top;
}

.assistance-clock-block > .clock-icon,
.assistance-clock-block > p {
    display: inline-block;
    vertical-align: top;
}

.assistance-phone-block > p,
.assistance-clock-block > p {
    width: 75%;
}

.assistance-phone-block > .phone-icon,
.assistance-clock-block > .clock-icon {
    width: 25%;
    text-align: center;
}

@media only screen and (max-width: 767px) {
    .assistance-block {
        padding: 0 10px 10px 10px;
    }
}