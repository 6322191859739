.account-summary-block {
    display: block;
    background-color: #f9f9f9;
    border: 1px solid #ededed;
    border-radius: 20px;
    width: 90%;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 50px;
    padding: 0 50px 30px 50px;
}

.account-summary-col-block {
    padding: 0px 10px;
    width: 33.33%;
}

.verify-button {
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    padding: 3px 10px;
    vertical-align: top;
    text-align: center;
    background-color: #e71d73;
    border-color: #e71d73;
    color: #ffffff;
    cursor: pointer;
}

.verify-button:hover {
    border: 1px solid #e71d73;
}

.account-summary-section {
    padding: 0 10px;
    margin-bottom: 25px;
}

/* .input-with-button-hidden :global .ant-input-affix-wrapper {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
} */

@media only screen and (max-width: 767px) {
    .account-summary-block {
        padding: 0px;
    }
    .account-summary-col-block {
        width: 100%;
    }
}