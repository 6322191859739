.meter-site-dropdown {
    min-width: 300px !important;
    max-height: 600px !important;
    overflow: auto !important;
}

.meter-site-dropdown :global .ant-select-tree-switcher {
    width: 12px;
}

.meter-site-dropdown :global .ant-select-tree-indent-unit {
    width: 12px;
}

.dunno-reg-id-container {
    position: relative;
    height: 30px;
}

.dunno-reg-id-container :global .ant-form-item {
    margin-bottom: 0;
    position: absolute;
    top: -20px;
    width: 200px;
}

@media only screen and (max-width: 575px) {
    .dunno-reg-id-container {
        width: 200px;
        margin: auto;
    }

    .dunno-reg-id-container :global .ant-form-item {
        top: -3px;
    }
}

.dunno-reg-id-label label {
    font-size: 11px !important;
}

.dunno-reg-id-label :global .ant-form-item-label {
    flex-basis: auto !important;
}

.dunno-reg-id-label :global .ant-form-item-control {
    flex-basis: auto !important;
}

.dunno-reg-id-label :global .ant-form-item-label>label::after {
    display: block !important;
}

.reissue-inv-label {
    margin: auto;
    width: 450px !important;
    max-width: 90%;
    justify-content: center;
    margin-bottom: 15px;
}

.reissue-inv-label label {
    cursor: pointer;
}

.reissue-inv-label :global .ant-form-item-label {
    max-width: 90% !important;
    flex-basis: auto !important;
    padding-left: 5px;
}

.reissue-inv-label :global .ant-form-item-control {
    flex-basis: auto !important;
}

.reissue-inv-label :global .ant-form-item-label>label::after {
    display: block !important;
}

.self-read-form :global .ant-image {
    display: block;
}

.meter-photo-serial-col {
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #ededed;
}

.site-selector-svc-type-icon {
    position: absolute;
    top: 6px;
    right: 26px;
    cursor: help;
}

.meter-site-dropdown :global .ant-select-tree-node-content-wrapper:hover {
    background: none;
}

:global .ant-select-tree-node-content-wrapper :local .meter-site-dropdown-enabled-item:hover {
    background-color: #f5f5f5;
}

:global .ant-select-selection-item :local .meter-site-dropdown-enabled-item {
    text-overflow: ellipsis;
    overflow: hidden;
}

.remove-reg-btn {
    position: relative;
}

.remove-reg-btn span {
    font-size: 18px;
    position: absolute;
    right: 0;
    top: -30px;
    color: #d32f2f;
    cursor: pointer;
}

.read-section-container {
    min-height: 200px;
    background-color: #ffffff;
    border-radius: 15px;
    margin-bottom: 20px;
    padding-bottom: 20px
}

/* section title */
.read-section-container:not(.read-date-picker)>div:first-child {
    font-weight: bold;
    color: #e71d73;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    height: 37px;
}

.read-section-container:not(.read-date-picker)>div:first-child>p:first-child {
    font-weight: bold;
    color: #e71d73;
}

.read-section-container.read-date-picker {
    height: 80px;
    min-height: 80px;
    display: flex;
    align-items: center;
    padding: 0;
}

.read-section-container.read-date-picker>div {
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 20px;
    padding-top: 25px;
}

.read-uploader {
    width: 60%;
    min-width: 150px;
    background-color: #f9f9f9;
    height: 150px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
}

.read-uploader>span:first-child {
    font-size: 80px;
    color: #c2c2c2;
}

.read-uploader>span:nth-child(2) {
    display: inline-block;
    width: 200px;
    text-align: center;
}

.form-button-container {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin: auto;
}

.disable-mask {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(249, 249, 249, 0.8);
    z-index: 2;
    top: 0;
}

.register-section-separator {
    width: 90%;
    margin: auto;
    margin-bottom: 15px;
}

.register-section-title {
    font-weight: bold;
    color: #e71d73;
    margin-left: 30px;
    font-size: 12px;
    margin-bottom: 10px;
}

.read-section-right-col-container {
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.register-uploader {
    width: 50%;
    min-width: 300px;
    background-color: #f9f9f9;
    height: 90px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 10px;
    position: relative;
}

.register-uploader>span:first-child {
    font-size: 40px;
    color: #c2c2c2;
}

.register-uploader>span:nth-child(2) {
    display: inline-block;
    width: 90%;
    text-align: center;
}

.meter-read-info-icon {
    cursor: help;
    margin-left: 10px;
}

.read-reason-container {
    min-width: 100%;
    background-color: #ffffff;
    border-radius: 15px;
    margin-bottom: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    flex-direction: column;
}

.read-reason-container label[title="Self Read Reason"] {
    font-weight: bold;
    color: #e71d73;
    font-size: 14px;
    padding-top: 10px;
    margin-bottom: 10px;
}

:global(.ant-radio-group) {
    display: flex;
    flex-direction: column;
    width: fit-content;
    margin: auto;
}

@media only screen and (max-width: 1052px) {
    .meter-photo-serial-col {
        flex-basis: 100%;
        border-right: none;
    }
}

@media only screen and (max-width: 575px) {
    .self-read-form :global .ant-form-item {
        width: 200px;
        flex-basis: auto;
        margin: auto;
        margin-bottom: 15px;
    }

    .site-selector-svc-type-icon {
        right: -16px;
    }

    .register-read-row {
        min-height: 350px;
    }

    .remove-reg-btn span {
        right: 20px;
    }
}