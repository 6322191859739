.referral-link-block {
    width: 90%;
    max-width: 1200px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    background-color: #f4f4f4;
    padding: 10px 50px 30px 50px;
    border: 1px solid #E0E1E2;
    border-radius: 20px;
}

.referral-link-heading {
    font-size: 20px;
    font-weight: bold;
    color: #e71d73;
}

.break-long-text {
    word-break: break-all;
}

@media only screen and (max-width: 767px) {
    .referral-link-block {
        padding: 0 10px 10px 10px;
    }
}
