.simple-pagination {
    display: none;
    text-align: center;
    margin-top: 20px;
}

.simple-pagination > .page-item {
    display: inline-block;
    padding: 5px;
    border: 1px solid #E0E1E2;
    background-color: #ffffff;
    width: 100px;
}

.simple-pagination > .page-item:first-child {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.simple-pagination > .page-item:last-child {
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.normal-pagination {
    text-align: right;
}

@media only screen and (max-width: 767px) {
    .simple-pagination {
        display: block;
    }

    .normal-pagination {
        display: none;
    }
}