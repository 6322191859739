.correspondence-block {
    display: block;
    background-color: #f4f4f4f4;
    border: 1px solid #E0E1E2;
    border-radius: 20px;
    width: 90%;
    max-width: 1200px;
    margin-right: auto;
    margin-left: auto;
    margin-top: 30px;
    padding: 60px 30px 10px 30px;
}

.correspondence-block > .correspondence-title {
    text-align: center;
}

.correspondence-block .correspondence-subblock {
    padding-left: 30px;
}

.correspondence-item {
    padding: 15px 30px 15px 30px;
    border: 1px solid #ededed;
    background-color: #ffffff;
    border-radius: 30px;
    margin-bottom: 10px;
    cursor: pointer;
}

.correspondence-item > p {
    margin: 0;
}

.correspondence-item .title {
    font-weight: bolder;
}

.correspondence-item .time-block {
    text-align: right;
}

.correspondence-item .time-block .time {
    display: inline-block;
    text-align: right;
    width: 120px;
}

.correspondence-item .time-block .time > p {
    margin: 0;
    display: inline-block;
    margin-right: 10px;
    color: #777777;
}

.pagination-block {
    margin-top: 20px;
}

@media only screen and (max-width: 767px) {
    .correspondence-item .time-block .time {
        text-align: right;
        width: 100%;
    }
}