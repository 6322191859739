a {
    color: #e71d73;
}
button.globird-button-primary,
.globird-button-primary.ant-btn,
.globird-button-primary .ant-btn {
    align-self: center;
    height: 50px;
    width: fit-content;
    margin-top: 20px;
    border: none;
    border-radius: 23px;
    background-color: #e71d73;
    color: #ffffff;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.globird-button-secondary.ant-btn,
.globird-button-secondary .ant-btn {
    align-self: center;
    height: 50px;
    width: fit-content;
    margin-top: 20px;
    border: none;
    border-radius: 23px;
    background-color: #FBAE47;
    color: #ffffff;
    font-size: 20px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

.globird-button-secondary.ant-btn[disabled],
.globird-button-secondary .ant-btn[disabled],
button.globird-button-primary[disabled],
.globird-button-primary.ant-btn[disabled],
.globird-button-primary .ant-btn[disabled] {
    cursor: no-drop;
    background-color: #c5c5c5;
}

.globird-button-primary-small {
    align-self: center;
    width: fit-content;
    border: none;
    border-radius: 23px;
    background-color: #e71d73;
    color: #ffffff;
    font-weight: bold;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.globird-button-default {
    border: 2px solid #dedede;
    border-radius: 23px;
    background-color: #ffffff;
    color: #797979;
    font-size: 13px;
    padding: 10px 15px 10px 15px;
    cursor: pointer;
}

.globird-link {
    color: #e71d73;
}

.globird-checkbox > .form-check-input:checked {
    background-color: #e71d73 !important;
    border-color: #e71d73 !important;
}

.globird-checkbox > label {
    font-size: 13px !important;
}

.globird-form-field label,
label.globird-form-field-label {
    font-weight: bold;
    color: #696969;
}

.globird-form-field i {
    max-width: 40px;
}

.globird-form {
    max-width: 95%;
    display: inline-block;
    padding: 20px 90px 20px 90px;
    background: inherit;
    border-style: solid;
    border-color: lavender;
    border-width: 1px;
    border-radius: 40px;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
}

.globird-nav-item {
    margin-right: 15px;
}

.globird-nav-btn {
    border: none;
    font-weight: bold;
    background-color: transparent;
}

.globird-nav-btn.active {
    border-bottom: 2px solid #e71d73;
}

@media only screen and (max-width: 600px) {
    .globird-form {
        display: inline-block;
        padding: 30px;
        background: inherit;
        border-style: solid;
        border-color: lavender;
        border-width: 1px;
        border-radius: 40px;
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
    }
}

.globird-form-title {
    text-align: center;
    margin-bottom: 20px;
}

.globird-form-field {
    text-align: left;
    margin-bottom: 10px;
}

.globird-form-field > label {
    font-size: 14px;
}

.globird-form-field > input,
input.globird-form-field-input {
    border-radius: 20px;
    border: solid #e2e0e0 2px;
    min-width: 250px;
    padding: 2px 13px 2px 13px;
    width: 100%;
}

.globird-form-field > input:focus,
input.globird-form-field-input:focus {
    outline: none;
    border-color: #e71d73;
}

.opacity {
    opacity: 0;
    z-index: -100;
}

.inopacity {
    opacity: 1;
    z-index: 0;
}

@keyframes expand-in {
    from {
        top: -30px;
    }
    to {
        top: 0px;
    }
}

/* date time picker css style */

.page-link {
    color: #e71d73;
    cursor: pointer;
}

.page-item:first-child .page-link {
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
}

.page-item:last-child .page-link {
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
}

.page-link:hover {
    color: #ffffff;
    background-color: #e71d73;
}

.page-item.active .page-link {
    color: #ffffff;
    background-color: #e71d73;
}

.globird-menu-option {
    position: relative;
    margin-top: -36px;
    width: 240px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.globird-menu-option .globird-menu-label {
    font-size: 13px;
    font-weight: bolder;
    color: #717171;
    margin: 0;
}

/* globird grid css style */

.globird-row-fix-horizontal {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.globird-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.globird-col {
    flex-grow: 1;
}

.globird-col-1 {
    width: 10%;
}

.globird-col-2 {
    width: 20%;
}

.globird-col-3 {
    width: 30%;
}

.globird-col-4 {
    width: 40%;
}

.globird-col-5 {
    width: 50%;
}

.globird-col-6 {
    width: 60%;
}

.globird-col-7 {
    width: 70%;
}

.globird-col-8 {
    width: 80%;
}

.globird-col-9 {
    width: 90%;
}

@media only screen and (max-width: 767px) {
    .globird-row {
        display: flex;
        flex-direction: column;
    }
}

/* globird grid css style */

/* globird badge css style */

.globird-badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 50rem;
}

.globird-badge.badge-green {
    background-color: #198754 !important;
}

.globird-badge.badge-yellow {
    background-color: #ffc107 !important;
}

.globird-badge.badge-globird {
    background-color: #e71d73 !important;
}

/* globird badge css style */

/* globird table */
.globird-table {
    width: 100%;
    margin-bottom: 1rem;
    color: #212529;
    vertical-align: top;
    border-color: #dee2e6;
}

.globird-table > :not(caption) > * > * {
    padding: 0.5rem 0.5rem;
    border-bottom: 1px solid #dee2e6;
}

.globird-table th {
    border-bottom: 1px solid #2b2b2b !important;
    text-align: left;
    word-break: keep-all;
}

.globird-table td {
    text-align: left;
    word-break: keep-all;
}

.globird-input-group > .globird-input-item {
    display: inline-block;
}

.globird-input-group > .globird-input-item:first-child > * {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
}

.globird-input-group > .globird-input-item:last-child > * {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
}

.globird-form-field input[type="text"].error,
input[type="password"].error {
    border-color: #e53935;
}

/* Used in LinkAccount, DirectDebit and PayMyBill */
.form-container {
    margin: auto;
    margin-top: 50px;
    background-color: #f9f9f9;
    width: 90%;
    max-width: 500px;
    min-width: 330px;
    min-height: 500px;
    border-radius: 20px;
    border: 1px solid #ededed;
}

.field-container {
    width: 70%;
    min-width: 200px;
    margin: auto;
    padding-top: 40px;
}

.form-field-tac [role="alert"] {
    /*term and condition tickbox*/
    font-size: 12px;
    padding-left: 23px;
}

.login {
    text-align: center;
    margin-top: 50px;
}

.router-block {
    margin-top: 30px;
}

.router-block > p {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 5px !important;
}

.login-form {
    width: 300px;
    text-align: initial;
}

.input-with-button-hidden .ant-input-affix-wrapper {
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
}

.inline-verify-button {
    border: 1px solid #d9d9d9;
    border-radius: 20px;
    padding: 3px 10px;
    vertical-align: top;
    text-align: center;
    background-color: #e71d73;
    border-color: #e71d73;
    color: #ffffff;
    cursor: pointer;
}

.inline-verify-button:hover {
    border: 1px solid #e71d73;
}

.form-save-button {
    width: 230px;
    margin: auto;
    display: block;
    margin-top: 20px !important;
}

@media only screen and (max-width: 767px) {
    .field-container {
        width: 80%;
        min-width: 200px;
        margin: auto;
        padding-top: 40px;
    }
}

p {
    margin: 0;
}

.form-spin-wrapper {
    display: inline-block;
    max-width: 95%;
}

.form-spin-wrapper .ant-spin-blur {
    opacity: 1;
}

.form-spin-wrapper .ant-spin-blur::after {
    border-radius: 40px;
}
