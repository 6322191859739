.globird-table th {
    color: #717171;
}

.globird-small-table {
    display: none;
}

.globird-small-table .row-block {

    margin: 10px 0 10px 0;
    border: 1px solid #E0E1E2;
    border-radius: 15px;
    padding: 10px;
}

.globird-small-table .globird-row-fix-horizontal {
    border-bottom: 1px solid #e0e1e2;
    padding: 5px 0 5px 0;
}

.globird-small-table .globird-row-fix-horizontal:last-child {
    border-bottom: 0px;
}

.globird-small-table .row-block .globird-row-fix-horizontal .header {
    color: #717171;
    font-weight: 500;
    word-break: keep-all;
}

@media only screen and (max-width: 767px) {
    .globird-table.wrapped-table {
        display: none;
    }

    .globird-small-table {
        display: block;
    }
}