.new-contact-button {
    border: 1px solid #e71d73;
    padding: 3px 10px;
    vertical-align: top;
    text-align: center;
    background-color: #e71d73;
    color: #ffffff;
    cursor: pointer;
    border-radius: 0 20px 20px 0 !important;
}

.new-contact-button-wrapper {
    width: 25%;
    display: inline-block;
}

button.new-contact-button>span::after{
    content: "Add new contact";
}

.account-holder-col-block {
    padding: 0 10px;
    width: 50%;
}

.agree-term-block {
    padding-left: 12px;
}

@media only screen and (max-width: 767px) {
    button.new-contact-button>span::after{
        content: "+";
    }

    .account-holder-col-block {
        width: 100%;
    }
}

.account-holder-information-heading {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 24px;
    color: #e71d73;
    text-align: center;
    padding-bottom: 20px;
}

.account-holder-information-text {
    color: #e71d73;
}