
/* rubik-300 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-600 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 600;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-800 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 800;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-800.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-800.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-300italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 300;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-300italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-900 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 900;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-900.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-900.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 400;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-500italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 500;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-500italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-500italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-600italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 600;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-600italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-800italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 800;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-800italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-800italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-700italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 700;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-700italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
/* rubik-900italic - latin */
@font-face {
  font-family: 'Rubik';
  font-style: italic;
  font-weight: 900;
  src: local(''),
       url('assets/fonts/rubik-v12-latin-900italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('assets/fonts/rubik-v12-latin-900italic.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  font-family: Rubik, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #e71d73;
}