.concession-tab-menu {
    background-color: #F9F9F9;
    padding: 0 1rem;
}

.manage-concession-form-wrapper {
    width: 45%;
}

.manage-concession-full-details {
    font-size: 0.7rem;
    margin-bottom: 0.5rem;
}

.manage-concession-notes-item {
    font-weight: 550;
    font-size: 0.8rem;
    color: #e71d73;
}

.incorrect-card-details {
    margin-bottom: 1.5rem;
    width: 80%;
}

.manage-concession-split-panel {
    display: flex;
    padding: 1rem 2.2rem;
    flex-wrap: wrap;
    justify-content: center;
}

.concession-history-wrapper {
    padding: 1rem 2.2rem;
}

.manage-concession-terms-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 45%;
}

.manage-concession-heading {
    padding-top: 0.5rem;
    padding-bottom: 1rem;
    font-weight: 700;
    font-size: 1.8rem;
}

.manage-concession-field-sub-text {
    font-size: 0.75rem;
}

.manage-concession-field-label {
    font-weight: 600;
    font-size: 0.9rem;
}

.concession-superman {
    border-radius: 100px;
    padding: 15px;
    width: 100%;
    background-color: #fff;
    display: flex;
    margin-bottom: 1rem;
}

.concession-superman-img {
    width: 150px;
    height: 150px;
    border-radius: 100px;
}

@media only screen and (max-width: 1500px) {
    .concession-superman-img {
        display: none;
    }
    .concession-split-divider {
        display: none;
    }
    .incorrect-card-details {
        width: 100%;
    }
    .manage-concession-form-wrapper {
        width: 100%;
    }
    .manage-concession-terms-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
}

.concession-split-divider {
    margin: 2.5rem;
    height: auto;
}

.concession-superman-text {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 24px;
    color: #e71d73;
    text-align: center;
    display: flex;
    align-items: center;
    width: inherit;
    justify-content: center;
}

.concession-form-item-nomargin {
    margin-bottom: 0;
}

.concession-warning-wrapper {
    display: flex;
    justify-content: center;
}

.concession-warning {
    padding: 30px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: fit-content;
}