.copy-button-wrapper {
    width: 25%;
    display: inline-block;
}

.copy-button  {
    border: 1px solid #e71d73;
    border-radius: 0 20px 20px 0;
    padding: 3px 10px;
    vertical-align: top;
    text-align: center;
    background-color: #e71d73;
    color: #ffffff;
    cursor: pointer;
    min-width: min-content;
    width: 10%;
    flex: 0 0 auto;
}

.referral-heading {
    font-size: 20px;
    font-weight: bold;
    color: #e71d73;
}

.referral-subheading {
    color: #696969;
    font-size: 16px;
    margin-bottom: 12px;
    font-weight: normal;
}

.referral-scanqrcode-text {
    color: #696969;
    font-size: 16px;
    margin-bottom: 0;
    font-weight: normal;
}

.page-heading {
    text-align: center;
    font-size: 18px;
    color: #2e2e2d;
    padding: 10px 0 10px 0;
    font-weight: normal;    
}

.terms-and-conditions-container {
    border-radius: 20px;
    background-color: #fbae47;
    max-width: 1000px;
    width: 90%;
    color: white;
    margin: 25px auto 0 auto;
    position: relative;
}

.conditions-container {
    padding: 30px 20px 15px 40px
}

.conditions-container ol {
    margin-bottom: 10px;
    list-style-position: inside;
    padding-left: 0;
}

.conditions-container p {
    font-size: 12px;
}

.conditions-container a {
    text-decoration: underline;
    color: white;
}

.conditions-container h3 {
    margin-bottom: 5px;
    font-weight: 500;
    color: white;
}

.refer-a-friend-img {
    position: absolute;
    bottom: 0;
    height: 130%;
    right: 10%;
}

@media only screen and (max-width: 480px) {
    .referral-qr-code {
        width: 100% !important;
        height: 100% !important;
    }
}

@media only screen and (max-width: 767px) {
    .refer-a-friend-img {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .refer-a-friend-img {
        right: 3%;
    }
}