.layout {
    height: 100%;
    min-height: 100vh;
}

.globird-background {
    background-repeat: no-repeat;
    background-size: auto 70vw;
    background-position: -5vw 65vh;
    background-image: url("assets/globirdLogo.svg");
}

@media only screen and (max-width: 1700px) {
    .globird-background {
        background-position: -9vw 70vh;
    }
}

@media only screen and (max-width: 1000px) {
    .globird-background {
        background-position: -9vw 75vh;
    }
}

@media only screen and (max-width: 600px) {
    .globird-background {
        background-position: -9vw 80vh;
        background-image: none;
    }
}

@media only screen and (min-width: 550px) {
    .logout-block {
        position: absolute;
        right: 0;
        margin: 30px 35px;
    }

    .my-account-banner {
        text-align: center;
        padding-top: 22px;
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 550px) {
    .logout-block {
        position: absolute;
        right: 0;
        margin: 15px;
    }

    .my-account-banner {
        text-align: center;
        padding-top: 55px;
        margin-bottom: 15px;
    }
}

.my-account-banner > .banner-text {
    font-size: 33px;
    color: rgb(236, 0, 140);
    font-weight: 400;
}

.my-account-banner > .banner-text > span {
    cursor: pointer;
}

.my-account-banner > .title {
    font-size: 24px;
    font-weight: 790;
    margin: 0;
}

.my-account-banner > .subtitle {
    font-size: 25px;
}

.my-account-banner img {
    height: 40px;
    display: inline-block;
    position: relative;
    vertical-align: baseline;
    top: 9px;
    left: 5px;
}

.logout-block .logout-button {
    transition-property: border-color, color;
    transition-duration: 300ms;
}

.logout-block .logout-button:hover {
    border-color: #e71d73;
    color: #e71d73;
}

.toast-block {
    position: fixed;
    bottom: 0;
}

.globird-progress {
    position: fixed;
    top: 0px;
    width: 100%;
    height: 5px;
    z-index: 10000;
}

.account-selector {
    min-width: 350px;
    max-width: 500px;
    margin: auto;
    text-align: center;
}

.dashboard-return-block {
    width: 100%;
    padding: 30px 0 60px 0;
}

.dashboard-return {
    border: 1px solid #ec008c;
    width: 100px;
    border-radius: 20px;
    height: 40px;
    margin: auto;
    cursor: pointer;
    display: flex;
    align-items: center;
}
