.move-panel {
    width: 100%;
    min-width: 260px;
}

.move-panel-text-section {
   max-width: 600px;
}

.move-panel-image-section {
    min-width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.move-panel-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.moveout-endofyear-text {
    font-size: 0.90rem;
    text-align: right;
    color: #e71d73;
}

@media only screen and (max-width: 1000px) {
    .moveout-endofyear-text {
        text-align: left;
    }
  }

.moveout-endofyear-text a {
    text-decoration: underline;
}

.moveout-heading-description {
    font-size: 0.90rem;
}

.moveout-heading {
    margin-bottom: 0%;
    font-weight: 700;
    font-size: 2.2rem;
}

.acceptance-checkbox {
    font-weight: 700;
    margin: 0;
    margin-left: 1.25rem;
}

.acceptance-checkbox-text {
    font-size: 1.25rem !important;
    opacity: 75%;
}
.selected-services-container {
    display: flex;
    justify-content: right;
    width: 100%;
}

.selected-services-toggle {
    margin-bottom: 0;
}

.selected-services-text {
    font-size: 12px !important;
    display: flex;
}

.move-table-row {
    background-color: #f5f5f5;
}

.move-out-date-item {
    margin: 0;
}

.move-table {
    margin-top: 1rem;
}

.move-form-item-title {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
}

.move-input {
    width: 100%;
    border-radius: 20px;
    overflow: hidden;
    background-color: #fff !important;
}

.move-supperman {
    border-radius: 100px;
    padding: 15px;
    width: 100%;
    background-color: #fff;
}

.move-supperman-img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
}

.move-supperman-text {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #e71d73;
    text-align: center;
    display: flex;
    align-items: center;
    width: inherit;
    justify-content: center;
}

.move-important-note {
    margin-top: 10px;
    color: #e71d73;
    font-size: 16px;
    line-height: 18px;
}

.move-important-note li {
    margin-bottom: 15px;
}

.confirmation-dialog-header {
    padding: 24px;
    color: white;
    background-color: #e71d73;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    font-size: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.confirmation-dialog-header-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.confirmation-dialog-header-text {
    text-align: center;
}

.confirmation-dialog-terms {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    align-content: center;
    padding: 24px;
    padding-bottom: 0px;
    padding-top: 10px
}

.confirmation-dialog-terms-header {
    margin-top: 10px;
    margin-bottom: 10px;
    color: #e71d73;
    font-size: 1rem;
    font-weight: 400;
}

.confirmation-dialog-terms-list {
    color: #e71d73;
    font-size: 1rem;
    line-height: 16px;
    font-weight: 600;
    margin-bottom: 0px;
    margin-right: 15px;
}

.confirmation-dialog-terms-list li {
    margin-bottom: 1rem;
}

.confirmation-dialog-site-list {
    padding: 24px;
}

.confirmation-dialog-footer {
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    padding: 24px;
    align-items: center;
}

.confirmation-dialog-footer-text {
    margin-bottom: 1rem;
    font-size: 1rem;
    font-weight: 500;
}

.confirmation-dialog-footer-or {
    border: 20px;
    padding-left: 10px;
    padding-right: 10px;
    font-size: 0.8rem;
    border-radius: 10px;
    color: white;
    background-color: black;
    margin-bottom: 0.5rem;
}

.row-selection-check {
    font-size: 12px;
    max-width: 13rem;
    color: #e71d73;
}

.ack-dialog-header {
    color: white;
    background-color: #198754;
    border-radius: 10px;
    margin: 0.5rem;
    font-size: 16px;
    padding: 0.2rem;
}

.ack-dialog-header-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
}

.ack-dialog-spacer-icon {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    width: 0.3rem;
}

.ack-dialog-body {
    text-align: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
}

.ack-dialog-close-icon {
    padding-left: 0.5rem;
}

.ack-dialog-close-button {
    background-color: white;
    color: black;
    border-color: black;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
}

.ack-dialog-site-list-item {
    color: #e71d73;
    font-size: 13px;
    font-weight: 700;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
}

.ack-dialog-footer-text {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.move-in-link {
    text-decoration: underline;
    color: black;
}

.site-address-column {
    max-width: 280px;
}

.site-status-column {
    max-width: 120px;
}

.list-icon-asterisk {
    list-style-type: none;
}

.list-icon-asterisk:before {
    content: "*";
    margin-left: -18px;
    margin-right: 10px;
}