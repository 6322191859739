.callback-block {
    max-width: 500px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background-color: #f4f4f4;
    padding: 0px 50px 30px 50px;
    border: 1px solid #E0E1E2;
    border-radius: 20px;
}

.callback-form {
    margin: 30px auto;
}