.payment-result {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.payment-result>p{
    max-width: 80%;
}

.form-field-amount [role="alert"]{
    font-size: 12px;
}

.payment-result-button {
    min-width: 150px;
    margin-top: 0 !important; 
}
