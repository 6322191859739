.self-serve-form-block {
    max-width: 1200px;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    background-color: #f4f4f4;
    padding: 0px 50px 30px 50px;
    border: 1px solid #E0E1E2;
    border-radius: 20px;
}

.self-serve-form-block .phone-icon {
    margin-right: 10px;
    color: #e71d73;
}

@media only screen and (max-width: 767px) {
    .self-serve-form-block {
        padding: 0 10px 10px 10px;
    }
}